import React from 'react'
import { Link } from 'gatsby'
import { BookOpen } from 'react-feather'

export default ({ to, children }) => (
  <div
    css={{
      color: 'black',
      textAlign: 'center',
      padding: 20,
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0,0,0,.1)'
    }}
  >
    <Link
      to={to}
      css={{
        color: 'black',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none'
      }}
    >
      <BookOpen size={20} />
      <span css={{ paddingLeft: 10 }}>{children}</span>
    </Link>
  </div>
)
