import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { ArrowRight } from 'react-feather'
import getQueryParam from 'get-query-param'

import ColorVote from '../../components/color/vote'
import SavesLink from '../../components/saves-link'
import VoteButton from '../../components/vote-button'

import { db } from '../../lib/client'

export default () => {
  const [color, setColor] = useState(null)

  useEffect(() => {
    const fetchColor = async () => {
      const id = getQueryParam('id', window.location.href)
      const result = await db
        .collection('color')
        .doc(id)
        .get()

      setColor(result.data())
    }

    fetchColor()
  }, [])

  if (!color) {
    return <h1>Loading Color</h1>
  }

  return (
    <ColorVote
      initialColor={color.data}
      voting={false}
      footer={
        <>
          <SavesLink to="/color/saved">Saved colors</SavesLink>
          <VoteButton
            title="Go to color page"
            onClick={() => navigate('/color')}
          >
            <ArrowRight />
          </VoteButton>
        </>
      }
    />
  )
}
