import React from 'react'
import { Grid, ArrowUp, ArrowRight, ArrowDown, Heart } from 'react-feather'

import Flex from './Flex'
import VoteButton from './vote-button'

export default ({ love, upvote, downvote, skip, gridView, toggleGridView }) => (
  <Flex>
    <VoteButton
      title="Save color"
      disabled={gridView}
      onClick={love}
      css={{
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.4
        }
      }}
    >
      <Heart size={20} />
    </VoteButton>
    <VoteButton title="Upvote color" onClick={upvote}>
      <ArrowUp size={20} />
    </VoteButton>
    <VoteButton title="Down vote color" onClick={downvote}>
      <ArrowDown size={20} />
    </VoteButton>
    <VoteButton title="Skip color" onClick={skip}>
      <ArrowRight size={20} />
    </VoteButton>
    <VoteButton title="Toggle grid view" onClick={toggleGridView}>
      <Grid size={20} />
    </VoteButton>
  </Flex>
)
