import React, { useState } from 'react'
import randomColor from 'random-hex-color'
import getContrast from 'get-contrast'
import Color from 'color'

import P from '../../elements/P'
import Td from '../../elements/Td'
import H5 from '../../elements/H5'
import Div from '../../elements/Div'
import Input from '../../elements/Input'
import Flex from '../Flex'
import SectionBorder from '../SectionBorder'
import Layout from '../VoteLayout'
import Label from '../Label'
import SavesLink from '../saves-link'
import VoteButtons from '../vote-buttons'

import useVote from '../../hooks/use-vote'

import GridView from './grid'

const Main = ({ color }) => (
  <div
    css={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'space-around'
    }}
  >
    <div
      css={{
        backgroundColor: color,
        height: '100%',
        width: '100%'
      }}
    />
  </div>
)

const Sidebar = ({ value }) => {
  const color = Color(value)

  return (
    <>
      <H5 mb={1}>Color</H5>
      <Flex py={1} alignItems="center">
        <Label>Hex</Label>
        <Input
          width={1}
          mr={2}
          readOnly={true}
          disabled={true}
          value={color.hex().toString()}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Label>HSL</Label>
        <Input
          width={1}
          mr={2}
          readOnly={true}
          disabled={true}
          value={color.hsl().toString()}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Label>RGB</Label>
        <Input
          width={1}
          mr={2}
          readOnly={true}
          disabled={true}
          value={color.rgb().toString()}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Contrast</H5>
      <Flex alignItems="flex-start">
        <P fontSize={0} width={2 / 3} pr={3} my={0}>
          Accessibility of {value} when combined with black or white. Scores
          range from 1-21.
        </P>
        <Div fontSize={0} width={1 / 3}>
          <table cellSpacing="0" cellPadding="0" width="100%">
            <thead>
              <tr>
                <th>Level</th>
                <th>Min. Contrast</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td fontWeight={600} pr={3} lineHeight={1.5}>
                  AA Large{' '}
                </Td>
                <Td textAlign="right">3</Td>
              </tr>
              <tr>
                <Td fontWeight={600} pr={3} lineHeight={1.5}>
                  AA
                </Td>
                <Td textAlign="right"> 4.5</Td>
              </tr>
              <tr>
                <Td fontWeight={600} pr={3} lineHeight={1.5}>
                  AAA
                </Td>
                <Td textAlign="right"> 7</Td>
              </tr>
            </tbody>
          </table>
        </Div>
      </Flex>
      <Flex flexWrap="nowrap" mt={4} fontSize={0}>
        <Flex flexWrap="nowrap" fontWeight={600} width={1 / 2} pr={2}>
          <P px={2} py={[1, 2]} my={0} width={1 / 2} bg={value} color="white">
            {getContrast.score(value, 'white')}{' '}
          </P>
          <P px={2} py={[1, 2]} my={0} width={1 / 2} color={value} bg="white">
            {getContrast.ratio(value, 'white').toFixed(2)}
          </P>
        </Flex>
        <Flex flexWrap="nowrap" fontWeight={600} width={1 / 2} pl={2}>
          <P px={2} py={[1, 2]} my={0} width={1 / 2} bg={value} color="black">
            {getContrast.score(value, 'black')}{' '}
          </P>
          <P px={2} py={[1, 2]} my={0} width={1 / 2} color={value} bg="black">
            {getContrast.ratio(value, 'black').toFixed(2)}
          </P>
        </Flex>
      </Flex>
    </>
  )
}

export default ({ initialColor, voting = true, footer }) => {
  const {
    love,
    upvote,
    downvote,
    skip,
    value: color,
    setValue: setColor,
    metadata,
    setMetadata
  } = useVote({
    componentName: 'color',
    generator: randomColor,
    initialValue: initialColor,
    voting
  })

  const { container } = metadata
  const setContainer = newContainer => {
    setMetadata({ ...metadata, container: newContainer })
  }

  const [gridView, setGridView] = useState(false)

  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleGridSelection = color => {
    setColor(color)
    setGridView(false)
  }

  return (
    <Layout
      main={
        gridView ? (
          <GridView onSelect={handleGridSelection} />
        ) : (
          <Main color={color} container={container} />
        )
      }
      sidebar={
        <Sidebar
          value={color}
          container={container}
          onContainerChange={(key, value) => {
            setContainer({
              ...container,
              [key]: Number(value)
            })
          }}
        />
      }
      footer={
        footer || (
          <>
            <SavesLink to="/color/saved">Saved colors</SavesLink>
            <VoteButtons
              love={love}
              upvote={upvote}
              downvote={downvote}
              skip={skip}
              gridView={gridView}
              toggleGridView={toggleGridView}
            />
          </>
        )
      }
    />
  )
}
