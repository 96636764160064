import React from 'react'
import randomColor from 'random-hex-color'

import Flex from '../Flex'

export const GridItem = ({ color, ...props }) => (
  <a
    css={{
      cursor: 'pointer',
      display: 'block',
      height: '100%',
      width: '100%'
    }}
    {...props}
  >
    <div
      css={{
        backgroundColor: color,
        height: '100%',
        width: '100%'
      }}
    />
  </a>
)

export const GridItemWrap = props => (
  <div
    css={{
      width: '25%',
      paddingLeft: '48px',
      paddingRight: '48px',
      paddingTop: '32px',
      height: '19vh'
    }}
    {...props}
  />
)

export const Grid = props => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
    {...props}
  />
)

export default ({ onSelect }) => (
  <Grid>
    {Array(20)
      .fill(0)
      .map((_, i) => {
        const color = randomColor()

        return (
          <GridItemWrap key={i}>
            <GridItem color={color} onClick={() => onSelect(color)} />
          </GridItemWrap>
        )
      })}
  </Grid>
)
