import { useState, useEffect } from 'react'

import { db } from '../lib/client'
import { UP_KEYCODE, DOWN_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

export default ({ initialValue, componentName, generator, voting = true }) => {
  const [value, setValue] = useState(initialValue || generator())
  const [metadata, setMetadata] = useState({})

  const shouldSkipVote = () => !voting

  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem(`${componentName}Votes`)) || []
  } catch (e) {}

  const love = async () => {
    if (shouldSkipVote()) {
      return
    }

    const { id } = await db.collection(componentName).add({
      up: true,
      data: value,
      metadata: { ...metadata, loved: true }
    })

    localStorage.setItem(
      `${componentName}Votes`,
      JSON.stringify([{ id }, ...votes].slice(0, 100).filter(Boolean))
    )

    setValue(generator())
  }

  const upvote = async () => {
    if (shouldSkipVote()) {
      return
    }

    await db.collection(componentName).add({
      up: true,
      data: value,
      metadata
    })

    setValue(generator())
  }

  const downvote = async () => {
    if (shouldSkipVote()) {
      return
    }

    await db.collection(componentName).add({
      up: false,
      data: value,
      metadata
    })

    setValue(generator())
  }

  const skip = () => {
    if (shouldSkipVote()) {
      return
    }

    setValue(generator())
  }

  const handleVoteKey = e => {
    if (
      e.keyCode === UP_KEYCODE ||
      e.keyCode === RIGHT_KEYCODE ||
      e.keyCode === DOWN_KEYCODE
    ) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === DOWN_KEYCODE) {
      downvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return {
    love,
    upvote,
    downvote,
    skip,
    value,
    setValue,
    metadata,
    setMetadata
  }
}
